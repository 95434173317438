import consumerApi from "api/services/consumer-api";
import { Coordinates } from "custom-types/Coordinates";
import { Dispensary } from "custom-types/Dispensary";
import logError from "utils/logError";

interface DispensaryData {
  hits: {
    dispensary: Dispensary[];
  };
}

const getShoppableDispensariesNearby = async (
  coordinates: Coordinates,
  take?: number,
): Promise<Dispensary[]> => {
  try {
    const {
      data: {
        hits: { dispensary },
      },
    }: { data: DispensaryData } = await consumerApi.get<DispensaryData>(
      "api/search/v1/shop",
      {
        params: {
          "filter[type]": "dispensary",
          lat: coordinates.lat,
          lon: coordinates.lon,
          take,
        },
      },
    );

    return dispensary;
  } catch (e) {
    logError(e.message, {
      functionName: "getShoppableDispensariesNearby",
      service: "consumer",
      statusCode: e.statusCode,
    });
  }

  return [];
};

export default getShoppableDispensariesNearby;
