import React from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { Action, Category } from "constants/events";
import { FeaturedDispensary } from "custom-types/FeaturedDispensary";
import useDomainCountryCode from "hooks/useDomainCountryCode";
import { useEventTracker } from "hooks/useEventTracker";
import { getUserId } from "redux/selectors/user";
import { getRoundedDistance } from "utils/distance";
import { localizeText } from "utils/localizeText";

import Carousel from "components/botanic/Carousel";
import PickupReadinessDispensaryCard from "components/PickupReadinessDispensaryCard";
import Subtitle from "components/Subtitle";
import TrackImpression from "components/TrackImpression";

const DISPENSARY_CARD_VARIANT = "platinum";

type Props = {
  className?: string;
  featuredDispensaries: FeaturedDispensary[];
};

const FeaturedDispensariesSection = ({
  className = "",
  featuredDispensaries,
}: Props) => {
  const { publishEvent, trackEvent, trackNonInteractionEvent } =
    useEventTracker();
  const countryCode = useDomainCountryCode();
  const userId = useSelector(getUserId);

  return featuredDispensaries?.length ? (
    <>
      <TrackImpression
        category="Featured Dispensaries Carousel"
        label="Featured Dispensaries Carousel"
        data-testid="featured-dispensaries-section"
        className={className}
      >
        <div className="container">
          <Carousel
            title={"Shops near you"}
            subtitle={
              <Subtitle
                title="Shops near you"
                subtitle="Showing you stores near"
                changeLocation
              />
            }
            seeMoreText={`See all ${localizeText("dispensaries", countryCode)}`}
            seeMoreHref="/dispensaries"
            showSeeMoreCard={true}
            seeMoreOnClick={() => {
              trackEvent(
                Category.Carousel,
                Action.click,
                "home featured dispensaries carousel show all",
              );
            }}
            seeMoreCardClass="rounded overflow-hidden shadow-low"
          >
            {featuredDispensaries.map((dispensary, slot) => {
              const deliveryEnabled =
                !!dispensary.deliveryEnabled ||
                !!dispensary.hasDeliveryEnabled ||
                Boolean(dispensary.currentStatuses?.delivery);
              const pickupEnabled =
                !!dispensary.pickupEnabled ||
                Boolean(dispensary.currentStatuses?.pickup);

              const customDimensions = {
                deal_id: dispensary.featuredDeal?.id,
                dispensaryDistance: getRoundedDistance(
                  dispensary.distanceMi,
                  countryCode,
                ),
                dispensaryId: dispensary.id,
                merchandisingCampaignId: dispensary.merchandisingCampaignId,
                userId: userId as number,
              };

              const impressionLabel = `dispensary card`;

              const eventOnClick = () => {
                publishEvent({
                  action: Action.click,
                  category: Category.homepageFeature,
                  label: impressionLabel,
                  ...customDimensions,
                });
              };

              const trackCtaClick = () => {
                publishEvent({
                  action: Action.click,
                  category: Category.homepageFeature,
                  label: `${impressionLabel} button`,
                  ...customDimensions,
                });
              };

              return (
                <TrackImpression
                  category={Category.homepageFeature}
                  className="h-full w-full relative"
                  key={`featured-dispensary-${slot}`}
                  label={impressionLabel}
                  trackingFunction={() => {
                    trackNonInteractionEvent(
                      Category.homepageFeature,
                      Action.impression,
                      impressionLabel,
                      {
                        deal_id: dispensary.featuredDeal?.id,
                        dispensaryDistance: getRoundedDistance(
                          dispensary.distanceMi,
                          countryCode,
                        ),
                        dispensaryId: dispensary.id,
                        merchandisingCampaignId:
                          dispensary.merchandisingCampaignId,
                        userId: userId as number,
                      },
                    );
                    // this is for kevel impression tracking, ask kayleigh
                    if (dispensary.impressionUrl) {
                      axios.get(dispensary.impressionUrl);
                    }
                  }}
                >
                  <PickupReadinessDispensaryCard
                    coverPhotoSizes={[196, null, null, 207, null, 207]}
                    ctaOnClick={trackCtaClick}
                    currentStatus={dispensary.currentStatuses?.store}
                    data-testid={`featured-dispensaries-section-card-${dispensary.id}`}
                    deal={dispensary.featuredDeal}
                    deliveryEnabled={deliveryEnabled}
                    disableLazyLoad={slot < 4}
                    dispensaryId={dispensary.id}
                    distanceMi={dispensary.distanceMi}
                    flags={dispensary.flags}
                    href={dispensary.href}
                    inStoreCartEnabled={dispensary.inStoreCartEnabled}
                    isAd={dispensary.isAd}
                    logoUrl={dispensary.logoUrl}
                    name={dispensary.name}
                    onClick={eventOnClick}
                    photoUrl={dispensary.coverPhotoUrl}
                    pickupEnabled={pickupEnabled}
                    preorderConfigured={dispensary.preorderConfigured}
                    preserveHeight={true}
                    rating={dispensary.starRating || dispensary.rating}
                    reviewCount={dispensary.reviewCount}
                    scheduleType="store"
                    isFeaturedDispensariesSection={true}
                    showDeal={true}
                    slug={dispensary.slug}
                    tags={dispensary.tags}
                    timeZone={dispensary.timeZone}
                    variant={DISPENSARY_CARD_VARIANT}
                    preload={slot < 4}
                  />
                </TrackImpression>
              );
            })}
          </Carousel>
        </div>
      </TrackImpression>
    </>
  ) : null;
};

export default FeaturedDispensariesSection;
